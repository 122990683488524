.card.candidate{
    height: 627px;
    width: 500px;
    background-color: rgb(197, 183, 156);
    background: url("metro.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.card.viewCertificate{
    height: 627px !important;
    width: 500px;
    background-color: rgb(197, 183, 156);
    background: url("metro.png");
    background-size: 1270px 626px;
}

.astrodivider {
    margin: 64px auto;
    width: 400px;
    max-width: 100%;
    position: relative;
    margin-left: 29em;

}

.astrodividermask {
    overflow: hidden;
    height: 20px;
}

.astrodividermask:after {
    content: '';
    display: block;
    margin: -25px auto 0;
    width: 100%;
    height: 25px;
    border-radius: 125px / 12px;
    box-shadow: 0 0 8px rgb(87 87 152);;
}

.astrodivider span {
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 100%;
    margin-bottom: -25px;
    left: 50%;
    margin-left: -25px;
    border-radius: 100%;
    box-shadow: 0 2px 4px rgb(87 87 152);;
    background: #fff;
}

.astrodivider i {
    position: absolute;
    top: 4px;
    bottom: 4px;
    left: 4px;
    right: 4px;
    border-radius: 100%;
    border: 1px dashed rgb(87 87 152);;
    text-align: center;
    line-height: 40px;
    font-style: normal;
    color: #049372;
}
div#myDiv {
    transform: skewY(20deg);
border: 1px solid black;
height: 800px;
margin-left: -13em;
margin-top: 13em;
background-color: blue;
}
.side{
    /* transform: rotate(270deg); */
    margin-left: -.5em;
    /* margin-top: 2em; */
    margin-bottom: -37.6em;
    height: 635px;
    width: 230px;
}
.card.construction{
    height: 627px;
    width: 500px;
    background-color: rgb(197, 183, 156);
    background: url("construction.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  
}

.card.construction1{
    height: auto;
    width: 500px;
    background-color: rgb(197, 183, 156);
    background: url("construction.jpg");
    background-size: 1265px 593px;
    background-repeat: no-repeat;
  
}
.rectangle {
    height: 150px;
    width: 100px;
    background-color: rgb(255, 255, 255);
    margin-top: 1em;
    margin-left: 3em;
    border-style: solid;
    border-color: rgb(50, 100, 165);
  }