ul.pagination{
    float: right !important;
}
.dataTables_filter{
    float: right !important;
}
/* table.dataTable>thead .sorting_asc:before, table.dataTable>thead .sorting_desc:after {
    opacity: 1;
}
table.dataTable>thead .sorting:before, table.dataTable>thead .sorting_asc:before, table.dataTable>thead .sorting_desc:before, table.dataTable>thead .sorting_asc_disabled:before, table.dataTable>thead .sorting_desc_disabled:before {
    right: 1em;
    content: "↑";
}
table.dataTable>thead .sorting:before, table.dataTable>thead .sorting:after, table.dataTable>thead .sorting_asc:before, table.dataTable>thead .sorting_asc:after, table.dataTable>thead .sorting_desc:before, table.dataTable>thead .sorting_desc:after, table.dataTable>thead .sorting_asc_disabled:before, table.dataTable>thead .sorting_asc_disabled:after, table.dataTable>thead .sorting_desc_disabled:before, table.dataTable>thead .sorting_desc_disabled:after {
    position: absolute;
    bottom: 0.9em;
    display: block;
    opacity: .3;
}
table.dataTable>thead .sorting:after, table.dataTable>thead .sorting_asc:after, table.dataTable>thead .sorting_desc:after, table.dataTable>thead .sorting_asc_disabled:after, table.dataTable>thead .sorting_desc_disabled:after {
    right: 0.5em;
    content: "↓";
} */
.nav-link{
	background-color: rgba(255,255,255,.9)  !important;
	color: black !important;
}
.nav .nav-link:focus{
background-color: #007bff!important;
color: white !important;
}
#Present:focus  {
    background-color: green;
    color: white;
}
#Absent:focus{
    background-color: #dc3545;
    color: white;
}#Pending:focus{
    background-color: grey;
    color: white;
}
.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorDefault.MuiChip-filledDefault.active.css-1iz24ed-MuiChip-root{
    background-color: green;
    color: white;
}
.fas.fa-sort{
    font-size: 10px;
}
@media print {
    .certificate { page-break-after: always; } /* page-break-after works, as well */
    .card.viewCertificate{
        height: 800px;
        background-size: 100% 100%;
        }
    .add{
        margin-bottom: 5em;
    }
    .card.construction{
        height: 800px;
        background-size: 100% 98.5%;
    }
    .card.candidate{
        background-size: 100% 98.5%;
        height: 800px;

    }
    .card.construction1{
        height: 800px;
        background-size: 100% 98.5%;
    }
    .at{
        margin-left: 4em;
    }
    .cer{
        margin-left: 3em;
    }
    /* .line{
        margin-right: 1em;
    }
    .cert{
        margin-left: 5em;
    }
   
    .ful{
        margin-left: 2em;
    } */
    @page {
        size: A4 landscape;
      }

      h4.text-center.metro-title{
          font-size: 30px;
          margin-right: 8em !important;
          margin-top: .5em !important;
      }
      h2.text-center.metro-certify{
        margin-top: -5px!important;
        font-size: 40px !important;

      }
      h1.cer{
          /* font-size: 30px !important; */
          margin-top: 1em !important;
      }
      h4.text-center.institute{
          font-size: 30px;
          margin-right: 4em !important;
      }
      h2.text-center.const-cert{
          margin-bottom: 1em !important;
      }
      h4.text-center.const-part{
          margin-top: 1em !important;
          margin-bottom: 1em !important;
      }
}

