.main {
    min-height: 92vh;
  }
  /* .table td {
    border: #000 solid 1px !important;
  }
  .table th {
    border: #000 solid 1px !important;
  } */

  
    .ToggleClassBtnRadio {
    width: 150px;
    }
    input[type="radio"] {
      display: none;
  }
  .MuiBox-root.css-19kzrtu {
    background-color: white;
  }
  table#quotation td, th{
    font-family: arial, sans-serif;
    border-collapse: collapse;
    border: 1px solid black;
    padding: 8px;
  }
  table#quotation{
    margin-left: auto;
    margin-right: auto;
  }